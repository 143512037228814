import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CaretDownFilled, CaretUpFilled, UserOutlined, QuestionCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { useAuth } from '../context/AuthContext';

interface UserDropdownProps {
    className?: string;
}

export const UserDropdown = ({ className = "" }: UserDropdownProps): JSX.Element => {
    const { user } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`relative ${className}`} ref={dropdownRef}>
            <div
                className="flex items-center gap-1 cursor-pointer hover:opacity-80 transition-all"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="relative flex items-center gap-1">
                    <img
                        src={user?.avatar}
                        alt={user?.personaname}
                        height="30"
                        width="30"
                        className="avatar h-6"
                    />
                    {user?.roles?.includes('vip') && (
                        <span className="absolute -top-2 -right-2 bg-[--color-gold] text-black text-[8px] px-1 py-0.5 rounded-full font-bold">
                            VIP
                        </span>
                    )}
                </div>
                <div className="transition-transform duration-200 text-white" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                    <CaretDownFilled />
                </div>
            </div>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-[var(--bg-gray)] bg-opacity-50 rounded-lg shadow-md z-50 border border-gray-700">
                    <div className="py-1">
                        <Link
                            to="/profile"
                            className="flex items-center gap-2 px-4 py-2 text-sm text-white hover:no-underline hover:opacity-80 hover:ml-2 transition-all rounded-md mx-1"
                            onClick={() => setIsOpen(false)}
                        >
                            <UserOutlined />
                            <span>Profile</span>
                        </Link>
                        <Link
                            to="/support"
                            className="flex items-center gap-2 px-4 py-2 text-sm text-white hover:no-underline hover:opacity-80 hover:ml-2 transition-all rounded-md mx-1"
                            onClick={() => setIsOpen(false)}
                        >
                            <QuestionCircleOutlined />
                            <span>Support</span>
                        </Link>
                        <div className="border-t border-gray-700 my-1"></div>
                        <a
                            href={`${process.env.REACT_APP_BASE_URL}/api/v1/auth/steam/logout`}
                            className="flex items-center gap-2 px-4 py-2 text-sm text-left hover:ml-2 transition-all hover:no-underline hover:text-red-500/80 rounded-md mx-1 text-red-500"
                        >
                            <LogoutOutlined />
                            <span>Sign Out</span>
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserDropdown; 